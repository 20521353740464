const storeLocales = {
  us: {
    locale: "en-US",
    shopName: process.env.VUE_APP_US_SHOPIFY_STORE,
    pathPrefix: "/us",
    currency: "USD",
    currencySymbol: "$"
  },
  uk: {
    locale: "en-GB",
    shopName: process.env.VUE_APP_UK_SHOPIFY_STORE,
    pathPrefix: "",
    currency: "GBP",
    currencySymbol: "£"
  }
}

const errorTypes = {
  AUTH_REGISTER: "AUTH_REGISTER",
  PROFILE_UPDATE: "PROFILE_UPDATE"
}

const priceAdjustmentSources = {
  PROMO: "promo",
  SUBSCRIPTION: "sub",
  GLOBAL: "coupon"
}

const promoTypes = {
  VALUE: "VALUE",
  MATCH: "MATCH"
}

const promoMatchableProperties = {
  RANGE: "matchList",
  PRODUCT: "matchProducts"
}

const discountTypes = {
  FIXED: "FIXED",
  PERCENTAGE: "PERCENTAGE"
}

const purchaseTypes = {
  BUYONCE: "BUYONCE",
  SUBSCRIPTION: "SUBSCRIPTION"
}

const subscriptionStates = {
  ACTIVE: "active",
  CANCELLED: "cancelled",
  EXPIRED: "expired"
}

const dateFormats = {
  DAY_DATE_MONTH_YEAR: {
    "en-GB": "EEEE, do MMMM yyyy",
    "en-US": "EEEE MMMM d, yyyy"
  }
}

const orderStatusOptions = {
  PROCESSING: "processing",
  CANCELLED: "cancelled",
  SHIPPED: "shipped",
  REFUNDED: "refunded"
}

const pageSlugPrefixes = {
  FEATURE: "featured",
  TEST: "test"
}

const productTypes = {
  PRODUCT: "Product",
  SET: "ProductBundle",
  GIFT_CARD: "ProductGiftCard"
}

const productRoutes = {
  [productTypes.PRODUCT]: "products",
  [productTypes.SET]: "sets"
}

const quantityStatusTypes = {
  OUT_OF_STOCK: "out-of-stock",
  IN_STOCK: "in-stock",
  LOW_STOCK: "low-on-stock"
}

const retailerDomains = {
  "en-US": {
    walmart: "walmart.com",
    cvs: "cvs.com"
  },
  "en-GB": {
    boots: "boots.com",
    superdrug: "superdrug.com"
  }
}

export {
  dateFormats,
  errorTypes,
  orderStatusOptions,
  pageSlugPrefixes,
  priceAdjustmentSources,
  productTypes,
  productRoutes,
  promoTypes,
  promoMatchableProperties,
  discountTypes,
  purchaseTypes,
  quantityStatusTypes,
  retailerDomains,
  subscriptionStates,
  storeLocales
}
